/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/src/fonts/roboto-v30-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/src/fonts/roboto-v30-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/src/fonts/roboto-v30-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: auto;
  scrollbar-color: #3e4095 #ffffff;
  text-transform: uppercase;
}

/* ===== Scrollbar CSS ===== */
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #3e4095;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

html {
  scroll-behavior: smooth;
}

html,
body {
  height: 100%;
}

ul,
ol {
  list-style: none;
}

li {
  position: relative;
}

a {
  /* display: inline-block; */
  text-decoration: none;
}

body {
  width: 100%;
  font-family: "Roboto", sans-serif;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  /* justify-content: center; */
  overflow-x: hidden;
}

.nav-link {
  color: #fff;
  transition: all 0.3s ease-in-out;
}

.nav-link:before {
  content: "";
  background: #fff;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover {
  background-position: 0;
}

.nav-link:hover::before {
  width: 100%;
}

/* LOADER */

.TruckLoader {
  position: relative;
  width: 150px;
  height: 40px;
  background: #444;
  animation: put-put 2s infinite, move-truck 10s infinite;
  background-size: 100% auto;
  border-radius: 4px;
  -webkit-box-reflect: below 15px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.1, transparent), to(rgba(255, 255, 255, 0.1)));
}
.TruckLoader:before,
.TruckLoader:after {
  content: "";
  display: block;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid #fff;
  background: #333;
  position: absolute;
  bottom: -10px;
}
.TruckLoader:before {
  left: 6px;
}
.TruckLoader:after {
  right: 6px;
}
.TruckLoader span {
  position: absolute;
  left: 30%;
  top: 25%;
}
.TruckLoader-cab {
  position: absolute;
  left: -35px;
  bottom: 0;
  width: 33px;
  height: 25px;
  background: #333;
  border-radius: 40% 0 4px 4px;
  -webkit-box-reflect: below 15px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.1, transparent), to(rgba(255, 255, 255, 0.1)));
}
.TruckLoader-cab:before,
.TruckLoader-cab:after {
  position: absolute;
  content: "";
  display: block;
  background: #333;
}
.TruckLoader-cab:before {
  width: 20px;
  height: 15px;
  top: -15px;
  right: 0;
  border-radius: 100% 0 0 0;
}
.TruckLoader-cab:after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #444;
  left: 5px;
  border: 2px solid #fff;
  background: #333;
  position: absolute;
  bottom: -10px;
}
.TruckLoader-smoke,
.TruckLoader-smoke:after,
.TruckLoader-smoke:before {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  right: -1px;
  bottom: -5px;
  border-radius: 50%;
  background: #333;
}
.TruckLoader-smoke {
  animation: smoke-1 2s infinite linear;
}
.TruckLoader-smoke:after {
  animation: smoke-2 3s infinite linear;
}
.TruckLoader-smoke:before {
  animation: smoke-3 4s infinite linear;
}
@keyframes put-put {
  0% {
    margin-top: 0px;
    height: 50px;
  }
  5% {
    margin-top: -2px;
    height: 52px;
  }
  20% {
    margin-top: -1px;
    height: 50px;
  }
  35% {
    margin-top: 1px;
    height: 49px;
  }
  40% {
    margin-top: -1px;
    height: 51px;
  }
  60% {
    margin-top: 1px;
    height: 49px;
  }
  75% {
    margin-top: 0px;
    height: 50px;
  }
  80% {
    margin-top: -4px;
    height: 52px;
  }
  100% {
    margin-top: 1px;
    height: 49px;
  }
}
@keyframes smoke-1 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.9;
  }
  100% {
    right: -30px;
    bottom: 5px;
    width: 30px;
    height: 30px;
    opacity: 0;
  }
}
@keyframes smoke-2 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.9;
  }
  100% {
    right: -60px;
    bottom: 8px;
    width: 25px;
    height: 25px;
    opacity: 0;
  }
}
@keyframes smoke-3 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0.9;
  }
  100% {
    right: -40px;
    bottom: 2px;
    width: 35px;
    height: 35px;
    opacity: 0;
  }
}
@keyframes move-truck {
  0% {
    margin-left: 90%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    margin-left: 0;
    opacity: 0;
  }
}

.inner {
  white-space: nowrap;
  transition: transform 0.3s ease;
}

.inner h3 {
  max-width: 800px; /* Adjust the value as needed */
  white-space: pre-wrap;
  /* line-height: 50px;  */
}

.inner p {
  max-width: 686px; /* Adjust the value as needed */
  white-space: pre-wrap; /* Preserve line breaks */
}

.service_icon:hover > svg {
  fill: #333 !important; /* Change to the desired dark color */
}
